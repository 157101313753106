export default function Modal({ isOpen, onClose, item }) {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-backdrop" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <table>
          <thead>
            <tr>
              <th>Rank</th>
              <th>Name</th>
              <th>Wins</th>
              <th>Plays</th>
              <th>Draws</th>
              <th>Points</th>
              <th>Knife Kills</th>
              <th>PSP Taken</th>
              <th>Time In Zone</th>
              <th>Sniper Kills</th>
              <th>Revivals</th>
              <th>Heals</th>
              <th>Flags Taken</th>
              <th>Flags Taking</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{1}</td>
              <td><a href={`https://steamcommunity.com/profiles/${item._id}`} target="_blank" rel="noreferrer">{item.displayName}</a></td>
              <td>{item.wins.toLocaleString()}</td>
              <td>{item.plays.toLocaleString()}</td>
              <td>{item.draws.toLocaleString()}</td>
              <td>{item.points.toLocaleString()}</td>
              <td>{item.knifeKills.toLocaleString()}</td>
              <td>{item.pspTaken.toLocaleString()}</td>
              <td>{item.timeInZone.toLocaleString()}</td>
              <td>{item.sniperKills.toLocaleString()}</td>
              <td>{item.revivals.toLocaleString()}</td>
              <td>{item.heals.toLocaleString()}</td>
              <td>{item.flagsTaken.toLocaleString()}</td>
              <td>{item.flagsTaking.toLocaleString()}</td>
            </tr>
          </tbody>
        </table>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
}