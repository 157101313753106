export default function Modal({ isOpen, onClose, item }) {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-backdrop" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <ul class="text-xs">
          <li class="text-sm">Default top 100 view</li>
          <li>Sort by kills, experience, headshots</li>
          <li>Each player name links to a modal of extra stats</li>
          <li>Search for any player by name (exact name search only)</li>
          <li>Search for any player by SteamID (numerical)</li>
          <li>Search for multiple players at once with a semi-colon e.g. 'ronzalo;dmc' (exact names / SteamIDs only)</li>
          <li>With modal open, player name links to steam profile</li>
        </ul>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
}