import { getPlayerStats } from "../http/tfe-backend-http";

export const handlePlayerSingle = (args) => {
  const { searchTerm, matchKey } = args;
  return getPlayerStats(args).then(players => {
        
    if (!players?.length) {
      return []
    }

    return players.filter(player => player[matchKey].toLowerCase() === searchTerm.toLowerCase());
  })
  .catch(error => {
    console.error('Error fetching data: ', error);
    return []
  })
}