const Levels =  {
	"base_url": "taskforcelite.com/ranks/",
    "max_level": 150,
    "levels": {
	1: {
	"Name": "1",
	"DisplayName": "Private I",
	"RequiredEXP": 0,
	"Icon": "Private.png",
	"Icon_Big": "Private_2.png"
},
	2: {
	"Name": "2",
	"DisplayName": "Private II",
	"RequiredEXP": 500,
	"Icon": "Private.png",
	"Icon_Big": "Private_2.png"
},
	3: {
	"Name": "3",
	"DisplayName": "Private III",
	"RequiredEXP": 1500,
	"Icon": "Private.png",
	"Icon_Big": "Private_2.png"
},
	4: {
	"Name": "4",
	"DisplayName": "Private First Class I",
	"RequiredEXP": 4000,
	"Icon": "PrivateFirst.png",
	"Icon_Big": "PrivateFirst_2.png"
},
	5: {
	"Name": "5",
	"DisplayName": "Private First Class I",
	"RequiredEXP": 6000,
	"Icon": "PrivateFirst.png",
	"Icon_Big": "PrivateFirst_2.png"
},
	6: {
	"Name": "6",
	"DisplayName": "Private First Class II",
	"RequiredEXP": 8000,
	"Icon": "PrivateFirst.png",
	"Icon_Big": "PrivateFirst_2.png"
},
	7: {
	"Name": "7",
	"DisplayName": "Specialist I",
	"RequiredEXP": 10000,
	"Icon": "Specialist.png",
	"Icon_Big": "Specialist_2.png"
},
	8: {
	"Name": "8",
	"DisplayName": "Specialist II",
	"RequiredEXP": 12000,
	"Icon": "Specialist.png",
	"Icon_Big": "Specialist_2.png"
},
	9: {
	"Name": "9",
	"DisplayName": "Specialist III",
	"RequiredEXP": 14000,
	"Icon": "Specialist.png",
	"Icon_Big": "Specialist_2.png"
},
	10: {
	"Name": "10",
	"DisplayName": "Corporal I",
	"RequiredEXP": 16000,
	"Icon": "Corporal.png",
	"Icon_Big": "Corporal_2.png"
},
	11: {
	"Name": "11",
	"DisplayName": "Corporal II",
	"RequiredEXP": 18000,
	"Icon": "Corporal.png",
	"Icon_Big": "Corporal_2.png"
},
	12: {
	"Name": "12",
	"DisplayName": "Corporal III",
	"RequiredEXP": 21000,
	"Icon": "Corporal.png",
	"Icon_Big": "Corporal_2.png"
},
	13: {
	"Name": "13",
	"DisplayName": "Sergeant I",
	"RequiredEXP": 24000,
	"Icon": "Sergeant.png",
	"Icon_Big": "Sergeant_2.png"
},
	14: {
	"Name": "14",
	"DisplayName": "Sergeant II",
	"RequiredEXP": 27000,
	"Icon": "Sergeant.png",
	"Icon_Big": "Sergeant_2.png"
},
	15: {
	"Name": "15",
	"DisplayName": "Sergeant III",
	"RequiredEXP": 30000,
	"Icon": "Sergeant.png",
	"Icon_Big": "Sergeant_2.png"
},
	16: {
	"Name": "16",
	"DisplayName": "Staff Sergeant I",
	"RequiredEXP": 33000,
	"Icon": "StaffSergeant.png",
	"Icon_Big": "StaffSergeant_2.png"
},
	17: {
	"Name": "17",
	"DisplayName": "Staff Sergeant II",
	"RequiredEXP": 36000,
	"Icon": "StaffSergeant.png",
	"Icon_Big": "StaffSergeant_2.png"
},
	18: {
	"Name": "18",
	"DisplayName": "Staff Sergeant III",
	"RequiredEXP": 39000,
	"Icon": "StaffSergeant.png",
	"Icon_Big": "StaffSergeant_2.png"
},
	19: {
	"Name": "19",
	"DisplayName": "Sergeant First Class I",
	"RequiredEXP": 42000,
	"Icon": "SergeantFirstClass.png",
	"Icon_Big": "SergeantFirstClass_2.png"
},
	20: {
	"Name": "20",
	"DisplayName": "Sergeant First Class II",
	"RequiredEXP": 45000,
	"Icon": "SergeantFirstClass.png",
	"Icon_Big": "SergeantFirstClass_2.png"
},
	21: {
	"Name": "21",
	"DisplayName": "Sergeant First Class III",
	"RequiredEXP": 48000,
	"Icon": "SergeantFirstClass.png",
	"Icon_Big": "SergeantFirstClass_2.png"
},
	22: {
	"Name": "22",
	"DisplayName": "Master Sergeant I",
	"RequiredEXP": 51000,
	"Icon": "MasterSergeant.png",
	"Icon_Big": "MasterSergeant_2.png"
},
	23: {
	"Name": "23",
	"DisplayName": "Master Sergeant II",
	"RequiredEXP": 54000,
	"Icon": "MasterSergeant.png",
	"Icon_Big": "MasterSergeant_2.png"
},
	24: {
	"Name": "24",
	"DisplayName": "Master Sergeant III",
	"RequiredEXP": 57000,
	"Icon": "MasterSergeant.png",
	"Icon_Big": "MasterSergeant_2.png"
},
	25: {
	"Name": "25",
	"DisplayName": "First Sergeant I",
	"RequiredEXP": 60000,
	"Icon": "FirstSergeant.png",
	"Icon_Big": "FirstSergeant_2.png"
},
	26: {
	"Name": "26",
	"DisplayName": "First Sergeant II",
	"RequiredEXP": 63000,
	"Icon": "FirstSergeant.png",
	"Icon_Big": "FirstSergeant_2.png"
},
	27: {
	"Name": "27",
	"DisplayName": "First Sergeant III",
	"RequiredEXP": 66000,
	"Icon": "FirstSergeant.png",
	"Icon_Big": "FirstSergeant_2.png"
},
	28: {
	"Name": "28",
	"DisplayName": "Sergeant Major I",
	"RequiredEXP": 69000,
	"Icon": "SergeantMajor.png",
	"Icon_Big": "SergeantMajor_2.png"
},
	29: {
	"Name": "29",
	"DisplayName": "Sergeant Major II",
	"RequiredEXP": 72000,
	"Icon": "SergeantMajor.png",
	"Icon_Big": "SergeantMajor_2.png"
},
	30: {
	"Name": "30",
	"DisplayName": "Sergeant Major III",
	"RequiredEXP": 75000,
	"Icon": "SergeantMajor.png",
	"Icon_Big": "SergeantMajor_2.png"
},
	31: {
	"Name": "31",
	"DisplayName": "Command Sergent Major I",
	"RequiredEXP": 79000,
	"Icon": "CommandSergeantMajor.png",
	"Icon_Big": "CommandSergeantMajor_2.png"
},
	32: {
	"Name": "32",
	"DisplayName": "Command Sergent Major II",
	"RequiredEXP": 83000,
	"Icon": "CommandSergeantMajor.png",
	"Icon_Big": "CommandSergeantMajor_2.png"
},
	33: {
	"Name": "33",
	"DisplayName": "Command Sergent Major III",
	"RequiredEXP": 87000,
	"Icon": "CommandSergeantMajor.png",
	"Icon_Big": "CommandSergeantMajor_2.png"
},
	34: {
	"Name": "34",
	"DisplayName": "Warrant Officer I",
	"RequiredEXP": 91000,
	"Icon": "WarrantOfficer.png",
	"Icon_Big": "WarrantOfficer_2.png"
},
	35: {
	"Name": "35",
	"DisplayName": "Warrant Officer II",
	"RequiredEXP": 95000,
	"Icon": "WarrantOfficer.png",
	"Icon_Big": "WarrantOfficer_2.png"
},
	36: {
	"Name": "36",
	"DisplayName": "Warrant Officer III",
	"RequiredEXP": 99000,
	"Icon": "WarrantOfficer.png",
	"Icon_Big": "WarrantOfficer_2.png"
},
	37: {
	"Name": "37",
	"DisplayName": "Chief Warrant Officer I",
	"RequiredEXP": 103000,
	"Icon": "ChiefWarrantOfficer.png",
	"Icon_Big": "ChiefWarrantOfficer_2.png"
},
	38: {
	"Name": "38",
	"DisplayName": "Chief Warrant Officer II",
	"RequiredEXP": 107000,
	"Icon": "ChiefWarrantOfficer.png",
	"Icon_Big": "ChiefWarrantOfficer_2.png"
},
	39: {
	"Name": "39",
	"DisplayName": "Chief Warrant Officer III",
	"RequiredEXP": 111000,
	"Icon": "ChiefWarrantOfficer.png",
	"Icon_Big": "ChiefWarrantOfficer_2.png"
},
	40: {
	"Name": "40",
	"DisplayName": "Second Lieutenant I",
	"RequiredEXP": 115000,
	"Icon": "SecondLieutenant.png",
	"Icon_Big": "SecondLieutenant_2.png"
},
	41: {
	"Name": "41",
	"DisplayName": "Second Lieutenant I",
	"RequiredEXP": 119000,
	"Icon": "SecondLieutenant.png",
	"Icon_Big": "SecondLieutenant_2.png"
},
	42: {
	"Name": "42",
	"DisplayName": "Second Lieutenant III",
	"RequiredEXP": 123000,
	"Icon": "SecondLieutenant.png",
	"Icon_Big": "SecondLieutenant_2.png"
},
	43: {
	"Name": "43",
	"DisplayName": "First Lieutenant I",
	"RequiredEXP": 127000,
	"Icon": "FirstLieutenant.png",
	"Icon_Big": "FirstLieutenant_2.png"
},
	44: {
	"Name": "44",
	"DisplayName": "First Lieutenant II",
	"RequiredEXP": 131000,
	"Icon": "FirstLieutenant.png",
	"Icon_Big": "FirstLieutenant_2.png"
},
	45: {
	"Name": "45",
	"DisplayName": "First Lieutenant III",
	"RequiredEXP": 135000,
	"Icon": "FirstLieutenant.png",
	"Icon_Big": "FirstLieutenant_2.png"
},
	46: {
	"Name": "46",
	"DisplayName": "Captain I",
	"RequiredEXP": 139000,
	"Icon": "Captain.png",
	"Icon_Big": "Captain_2.png"
},
	47: {
	"Name": "47",
	"DisplayName": "Captain II",
	"RequiredEXP": 143000,
	"Icon": "Captain.png",
	"Icon_Big": "Captain_2.png"
},
	48: {
	"Name": "48",
	"DisplayName": "Captain III",
	"RequiredEXP": 147000,
	"Icon": "Captain.png",
	"Icon_Big": "Captain_2.png"
},
	49: {
	"Name": "49",
	"DisplayName": "Major I",
	"RequiredEXP": 151000,
	"Icon": "Major.png",
	"Icon_Big": "Major_2.png"
},
	50: {
	"Name": "50",
	"DisplayName": "Major II",
	"RequiredEXP": 155000,
	"Icon": "Major.png",
	"Icon_Big": "Major_2.png"
},
	51: {
	"Name": "51",
	"DisplayName": "Major III",
	"RequiredEXP": 160000,
	"Icon": "Major.png",
	"Icon_Big": "Major_2.png"
},
	52: {
	"Name": "52",
	"DisplayName": "Lieutenant Colonel I",
	"RequiredEXP": 165000,
	"Icon": "LieutenantColonel.png",
	"Icon_Big": "LieutenantColonel_2.png"
},
	53: {
	"Name": "53",
	"DisplayName": "Lieutenant Colonel II",
	"RequiredEXP": 170000,
	"Icon": "LieutenantColonel.png",
	"Icon_Big": "LieutenantColonel_2.png"
},
	54: {
	"Name": "54",
	"DisplayName": "Lieutenant Colonel III",
	"RequiredEXP": 175000,
	"Icon": "LieutenantColonel.png",
	"Icon_Big": "LieutenantColonel_2.png"
},
	55: {
	"Name": "55",
	"DisplayName": "Colonel I",
	"RequiredEXP": 180000,
	"Icon": "Colonel.png",
	"Icon_Big": "Colonel_2.png"
},
	56: {
	"Name": "56",
	"DisplayName": "Colonel II",
	"RequiredEXP": 185000,
	"Icon": "Colonel.png",
	"Icon_Big": "Colonel_2.png"
},
	57: {
	"Name": "57",
	"DisplayName": "Colonel III",
	"RequiredEXP": 190000,
	"Icon": "Colonel.png",
	"Icon_Big": "Colonel_2.png"
},
	58: {
	"Name": "58",
	"DisplayName": "Brigadier General I",
	"RequiredEXP": 195000,
	"Icon": "BrigadierGeneral.png",
	"Icon_Big": "BrigadierGeneral_2.png"
},
	59: {
	"Name": "59",
	"DisplayName": "Brigadier General II",
	"RequiredEXP": 200000,
	"Icon": "BrigadierGeneral.png",
	"Icon_Big": "BrigadierGeneral_2.png"
},
	60: {
	"Name": "60",
	"DisplayName": "Brigadier General III",
	"RequiredEXP": 205000,
	"Icon": "BrigadierGeneral.png",
	"Icon_Big": "BrigadierGeneral_2.png"
},
	61: {
	"Name": "61",
	"DisplayName": "Major General I",
	"RequiredEXP": 210000,
	"Icon": "MajorGeneral.png",
	"Icon_Big": "MajorGeneral_2.png"
},
	62: {
	"Name": "62",
	"DisplayName": "Major General II",
	"RequiredEXP": 215000,
	"Icon": "MajorGeneral.png",
	"Icon_Big": "MajorGeneral_2.png"
},
	63: {
	"Name": "63",
	"DisplayName": "Major General III",
	"RequiredEXP": 220000,
	"Icon": "MajorGeneral.png",
	"Icon_Big": "MajorGeneral_2.png"
},
	64: {
	"Name": "64",
	"DisplayName": "Lieutenant General I",
	"RequiredEXP": 225000,
	"Icon": "LieutenantGeneral.png",
	"Icon_Big": "LieutenantGeneral_2.png"
},
	65: {
	"Name": "65",
	"DisplayName": "Lieutenant General II",
	"RequiredEXP": 230000,
	"Icon": "LieutenantGeneral.png",
	"Icon_Big": "LieutenantGeneral_2.png"
},
	66: {
	"Name": "66",
	"DisplayName": "Lieutenant General III",
	"RequiredEXP": 235000,
	"Icon": "LieutenantGeneral.png",
	"Icon_Big": "LieutenantGeneral_2.png"
},
	67: {
	"Name": "67",
	"DisplayName": "General I",
	"RequiredEXP": 240000,
	"Icon": "General.png",
	"Icon_Big": "General_2.png"
},
	68: {
	"Name": "68",
	"DisplayName": "General II",
	"RequiredEXP": 245000,
	"Icon": "General.png",
	"Icon_Big": "General_2.png"
},
	69: {
	"Name": "69",
	"DisplayName": "General III",
	"RequiredEXP": 250000,
	"Icon": "General.png",
	"Icon_Big": "General_2.png"
},
	70: {
	"Name": "70",
	"DisplayName": "One Star General I",
	"RequiredEXP": 255000,
	"Icon": "gold_star1.png",
	"Icon_Big": "gold_star1_big.png"
},
	71: {
	"Name": "71",
	"DisplayName": "One Star General II",
	"RequiredEXP": 261000,
	"Icon": "gold_star1.png",
	"Icon_Big": "gold_star1_big.png"
},
	72: {
	"Name": "72",
	"DisplayName": "One Star General III",
	"RequiredEXP": 267000,
	"Icon": "gold_star1.png",
	"Icon_Big": "gold_star1_big.png"
},
	73: {
	"Name": "73",
	"DisplayName": "Two Star General I",
	"RequiredEXP": 273000,
	"Icon": "gold_star2.png",
	"Icon_Big": "gold_star2_big.png"
},
	74: {
	"Name": "74",
	"DisplayName": "Two Star General II",
	"RequiredEXP": 279000,
	"Icon": "gold_star2.png",
	"Icon_Big": "gold_star2_big.png"
},
	75: {
	"Name": "75",
	"DisplayName": "Two Star General III",
	"RequiredEXP": 285000,
	"Icon": "gold_star2.png",
	"Icon_Big": "gold_star2_big.png"
},
	76: {
	"Name": "76",
	"DisplayName": "Three Star General I",
	"RequiredEXP": 291000,
	"Icon": "gold_star3.png",
	"Icon_Big": "gold_star3_big.png"
},
	77: {
	"Name": "77",
	"DisplayName": "Three Star General II",
	"RequiredEXP": 297000,
	"Icon": "gold_star3.png",
	"Icon_Big": "gold_star3_big.png"
},
	78: {
	"Name": "78",
	"DisplayName": "Three Star General III",
	"RequiredEXP": 303000,
	"Icon": "gold_star3.png",
	"Icon_Big": "gold_star3_big.png"
},
	79: {
	"Name": "79",
	"DisplayName": "Four Star General I",
	"RequiredEXP": 310000,
	"Icon": "gold_star4.png",
	"Icon_Big": "gold_star4_big.png"
},
	80: {
	"Name": "80",
	"DisplayName": "Four Star General II",
	"RequiredEXP": 317000,
	"Icon": "gold_star4.png",
	"Icon_Big": "gold_star4_big.png"
},
	81: {
	"Name": "81",
	"DisplayName": "Four Star General III",
	"RequiredEXP": 324000,
	"Icon": "gold_star4.png",
	"Icon_Big": "gold_star4_big.png"
},
	82: {
	"Name": "82",
	"DisplayName": "Five Star General",
	"RequiredEXP": 331000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	83: {
	"Name": "83",
	"DisplayName": "Five Star General",
	"RequiredEXP": 338000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	84: {
	"Name": "84",
	"DisplayName": "Five Star General",
	"RequiredEXP": 345000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	85: {
	"Name": "85",
	"DisplayName": "Five Star General",
	"RequiredEXP": 352000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	86: {
	"Name": "86",
	"DisplayName": "Five Star General",
	"RequiredEXP": 360000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	87: {
	"Name": "87",
	"DisplayName": "Five Star General",
	"RequiredEXP": 368000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	88: {
	"Name": "88",
	"DisplayName": "Five Star General",
	"RequiredEXP": 376000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	89: {
	"Name": "89",
	"DisplayName": "Five Star General",
	"RequiredEXP": 384000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	90: {
	"Name": "90",
	"DisplayName": "Five Star General",
	"RequiredEXP": 392000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	91: {
	"Name": "91",
	"DisplayName": "Five Star General",
	"RequiredEXP": 400000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	92: {
	"Name": "92",
	"DisplayName": "Five Star General",
	"RequiredEXP": 408000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	93: {
	"Name": "93",
	"DisplayName": "Five Star General",
	"RequiredEXP": 417000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	94: {
	"Name": "94",
	"DisplayName": "Five Star General",
	"RequiredEXP": 426000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	95: {
	"Name": "95",
	"DisplayName": "Five Star General",
	"RequiredEXP": 435000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	96: {
	"Name": "96",
	"DisplayName": "Five Star General",
	"RequiredEXP": 444000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	97: {
	"Name": "97",
	"DisplayName": "Five Star General",
	"RequiredEXP": 453000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	98: {
	"Name": "98",
	"DisplayName": "Five Star General",
	"RequiredEXP": 463000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	99: {
	"Name": "99",
	"DisplayName": "Five Star General",
	"RequiredEXP": 473000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	100: {
	"Name": "100",
	"DisplayName": "Five Star General",
	"RequiredEXP": 483000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	101: {
	"Name": "101",
	"DisplayName": "Five Star General",
	"RequiredEXP": 493000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	102: {
	"Name": "102",
	"DisplayName": "Five Star General",
	"RequiredEXP": 503000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	103: {
	"Name": "103",
	"DisplayName": "Five Star General",
	"RequiredEXP": 514000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	104: {
	"Name": "104",
	"DisplayName": "Five Star General",
	"RequiredEXP": 525000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	105: {
	"Name": "105",
	"DisplayName": "Five Star General",
	"RequiredEXP": 536000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	106: {
	"Name": "106",
	"DisplayName": "Five Star General",
	"RequiredEXP": 547000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	107: {
	"Name": "107",
	"DisplayName": "Five Star General",
	"RequiredEXP": 558000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	108: {
	"Name": "108",
	"DisplayName": "Five Star General",
	"RequiredEXP": 570000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	109: {
	"Name": "109",
	"DisplayName": "Five Star General",
	"RequiredEXP": 582000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	110: {
	"Name": "110",
	"DisplayName": "Five Star General",
	"RequiredEXP": 594000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	111: {
	"Name": "111",
	"DisplayName": "Five Star General",
	"RequiredEXP": 606000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	112: {
	"Name": "112",
	"DisplayName": "Five Star General",
	"RequiredEXP": 619000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	113: {
	"Name": "113",
	"DisplayName": "Five Star General",
	"RequiredEXP": 632000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	114: {
	"Name": "114",
	"DisplayName": "Five Star General",
	"RequiredEXP": 645000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	115: {
	"Name": "115",
	"DisplayName": "Five Star General",
	"RequiredEXP": 658000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	116: {
	"Name": "116",
	"DisplayName": "Five Star General",
	"RequiredEXP": 672000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	117: {
	"Name": "117",
	"DisplayName": "Five Star General",
	"RequiredEXP": 686000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	118: {
	"Name": "118",
	"DisplayName": "Five Star General",
	"RequiredEXP": 700000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	119: {
	"Name": "119",
	"DisplayName": "Five Star General",
	"RequiredEXP": 714000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	120: {
	"Name": "120",
	"DisplayName": "Five Star General",
	"RequiredEXP": 729000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	121: {
	"Name": "121",
	"DisplayName": "Five Star General",
	"RequiredEXP": 744000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	122: {
	"Name": "122",
	"DisplayName": "Five Star General",
	"RequiredEXP": 759000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	123: {
	"Name": "123",
	"DisplayName": "Five Star General",
	"RequiredEXP": 775000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	124: {
	"Name": "124",
	"DisplayName": "Five Star General",
	"RequiredEXP": 791000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	125: {
	"Name": "125",
	"DisplayName": "Five Star General",
	"RequiredEXP": 807000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	126: {
	"Name": "126",
	"DisplayName": "Five Star General",
	"RequiredEXP": 824000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	127: {
	"Name": "127",
	"DisplayName": "Five Star General",
	"RequiredEXP": 841000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	128: {
	"Name": "128",
	"DisplayName": "Five Star General",
	"RequiredEXP": 858000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	129: {
	"Name": "129",
	"DisplayName": "Five Star General",
	"RequiredEXP": 876000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	130: {
	"Name": "130",
	"DisplayName": "Five Star General",
	"RequiredEXP": 894000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	131: {
	"Name": "131",
	"DisplayName": "Five Star General",
	"RequiredEXP": 912000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	132: {
	"Name": "132",
	"DisplayName": "Five Star General",
	"RequiredEXP": 931000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	133: {
	"Name": "133",
	"DisplayName": "Five Star General",
	"RequiredEXP": 950000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	134: {
	"Name": "134",
	"DisplayName": "Five Star General",
	"RequiredEXP": 969000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	135: {
	"Name": "135",
	"DisplayName": "Five Star General",
	"RequiredEXP": 989000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	136: {
	"Name": "136",
	"DisplayName": "Five Star General",
	"RequiredEXP": 1009000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	137: {
	"Name": "137",
	"DisplayName": "Five Star General",
	"RequiredEXP": 1030000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	138: {
	"Name": "138",
	"DisplayName": "Five Star General",
	"RequiredEXP": 1051000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	139: {
	"Name": "139",
	"DisplayName": "Five Star General",
	"RequiredEXP": 1073000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	140: {
	"Name": "140",
	"DisplayName": "Five Star General",
	"RequiredEXP": 1095000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	141: {
	"Name": "141",
	"DisplayName": "Five Star General",
	"RequiredEXP": 1117000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	142: {
	"Name": "142",
	"DisplayName": "Five Star General",
	"RequiredEXP": 1140000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	143: {
	"Name": "143",
	"DisplayName": "Five Star General",
	"RequiredEXP": 1163000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	144: {
	"Name": "144",
	"DisplayName": "Five Star General",
	"RequiredEXP": 1187000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	145: {
	"Name": "145",
	"DisplayName": "Five Star General",
	"RequiredEXP": 1211000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	146: {
	"Name": "146",
	"DisplayName": "Five Star General",
	"RequiredEXP": 1236000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	147: {
	"Name": "147",
	"DisplayName": "Five Star General",
	"RequiredEXP": 1261000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	148: {
	"Name": "148",
	"DisplayName": "Five Star General",
	"RequiredEXP": 1287000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	149: {
	"Name": "149",
	"DisplayName": "Five Star General",
	"RequiredEXP": 1313000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
},
	150: {
	"Name": "150",
	"DisplayName": "Five Star General",
	"RequiredEXP": 1340000,
	"Icon": "GeneralOfArmy.png",
	"Icon_Big": "GeneralOfArmy_2.png"
}
		}};

export default Levels;
