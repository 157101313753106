import React, { useState, useEffect } from 'react';
import './App.css';
import FeaturesModal from './FeaturesModal';
import StatsModal from './StatsModal';
import Levels from './LevelInfo';
import { getTop100 } from './http/tfe-backend-http';
import BuildData from './metadata/BuildData.json';
import { handlePlayerSingle } from './helpers/tfe-backend.helpers';
import { filterToSort, responseSortMap } from './constants/tfe-backend.constants';
import { TASK_FORCE_ELITE_LOGO_URL } from './constants/images';

const BuildTimeStamp = new Date(BuildData.buildDateTime);

function App() {
  const [playerList, setPlayerList] = useState([]);
  const [playerSearchTermInput, setPlayerSearchTerm] = useState('');
  const [searchType, setSearchType] = useState('DisplayName');
  const [searchStatsType, setSearchStatsType] = useState('global');
  const [sortParameter, setSortParameter] = useState('experience');
  const [isStatsModalOpen, setStatsModalOpen] = useState(false);
  const [isFeaturedModalOpen, setFeaturedModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [searchString, setSearchString] = useState('You are currently viewing the top 100 players, sorted by experience, using global stats');

  const handleStatsModal = (rowData) => {
    setSelectedRowData(rowData);
    setStatsModalOpen(true);
  };

  const handleFeaturedModal = () => {
    setFeaturedModalOpen(true);
  };

  const handleMainList = (args) => {
    getTop100(args)
      .then(players => {
        setPlayerList(players);
      })
      .catch(error => {
        console.error('Error fetching data: ', error);
      });
  }

  const handleSearch = (event) => {
    event.preventDefault(); // Prevent the default form submit action
    let playerString = playerSearchTermInput ? playerSearchTermInput : 'the top 100 players'
    setSearchString(`You are currently viewing ${playerString}, ordered by ${sortParameter}, using ${searchStatsType} stats`)
    const filter = filterToSort[sortParameter];

    if (!playerSearchTermInput.length) {
      return handleMainList({ searchStatsType, filter });
    }

    const matchKey = searchType === 'SteamID' ? '_id' : 'displayName';
    const isMultiPlayerSearch = playerSearchTermInput.includes(';');

    if (!isMultiPlayerSearch) {
      return handlePlayerSingle({ 
        searchTerm: playerSearchTermInput, searchType, filter, searchStatsType, matchKey 
      })
      .then(players => {
        setPlayerList(players)
      });
    }

    const playersToSearch = playerSearchTermInput.split(';');

    return Promise.all(
      playersToSearch.map(playerSearchTerm => 
        handlePlayerSingle({ searchTerm: playerSearchTerm, searchType, filter, searchStatsType, matchKey }),
      )).then(responses => {
        const filterSort = responseSortMap[sortParameter] || 'exp';
        const flattenedResponse = responses.flat();
        setPlayerList(flattenedResponse.sort((a, b) => b[filterSort] - a[filterSort]))
      })
  };

  useEffect(() => {
    handleMainList({ searchStatsType, filter: filterToSort[sortParameter] });
  }, []);

  return (
    <div className="App">
        <a href="/"><img src={TASK_FORCE_ELITE_LOGO_URL} width="250" alt={`RSS Logo`}></img></a>
        <form className="search-container" onSubmit={handleSearch}>
          <input
              type="text"
              id="playerSearch"
              value={playerSearchTermInput}
              onChange={(e) => setPlayerSearchTerm(e.target.value)}
              aria-label="Player Name or steam ID"
          />
          <select
              value={searchType}
              id="searchType"
              onChange={(e) => setSearchType(e.target.value)}
              title="type"
          >
            <option value="DisplayName">DisplayName</option>
            <option value="SteamID">SteamID</option>
          </select>
          <button type="submit">Search</button>
          <select
              value={sortParameter}
              id="sortType"
              onChange={(e) => setSortParameter(e.target.value)}
              title="type"
          >
            <option value="experience">Experience</option>
            <option value="kills">Kills</option>
            <option value="headshots">Headshots</option>
          </select>
        <button type="submit">Sort</button>
        <select
            value={searchStatsType}
            onChange={(e) => setSearchStatsType(e.target.value)}
            title="type"
            class="p-2 text-sm border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          >
          <option value="global">Global Stats</option>
          <option value="community">Community Stats</option>
          <option value="official">Official Stats</option>
        </select>
        <button type="submit">Filter</button>
          <span onClick={() => handleFeaturedModal()} style={{cursor: 'pointer'}}>ℹ️</span>
      </form>
        {searchString ? <p className='search-summary'>{searchString}</p> : ''}
        <table className="sticky-header">
          <thead>
          <tr>
            <th>Rank</th>
            <th>Name</th>
            <th>Badge</th>
            <th>Level</th>
            <th>Experience</th>
            <th>Wins</th>
            <th>Kills</th>
            <th>Deaths</th>
            <th>K/D Ratio</th>
            <th>Headshots</th>
            <th>Headshot %</th>
          </tr>
          </thead>
          <tbody>
          {playerList.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td 
                  onClick={() => handleStatsModal(item)} 
                  style={{cursor: 'pointer', color: '#61dafb', style: 'underline'}}>
                  {item.displayName}
                </td>
                <td>
                  <img 
                    src={`https://taskforceelite.com/images/levels/${Levels.levels[item.level].Icon}`}
                    height="40" 
                    alt={`Level ${item.level}`}
                  />
                </td>
                <td>{item.level}</td>
                <td>{item.exp.toLocaleString()}</td>
                <td>{item.wins.toLocaleString()}</td>
                <td 
                  onClick={() => handleStatsModal(item)} 
                  style={{cursor: 'pointer'}}>{item.kills.toLocaleString()}
                </td>
                <td>{item.deaths.toLocaleString()}</td>
                <td className={(item.kills / item.deaths) > 1 ? 'high' : 'low'}>{(item.kills / item.deaths).toFixed(2)}</td>
                <td>{item.heads.toLocaleString()}</td>
                <td className={((item.heads / item.kills) * 100) > 25 ? 'high' : 'low'}>{((item.heads / item.kills) * 100).toFixed(2)}%</td>
              </tr>
          ))}
          </tbody>
      </table>
        <StatsModal item={selectedRowData} isOpen={isStatsModalOpen} onClose={() => setStatsModalOpen(false)}/>
        <FeaturesModal isOpen={isFeaturedModalOpen} onClose={() => setFeaturedModalOpen(false)}/>
        <span className="buildInfo">Build: {BuildData.gitInfo} - on {BuildTimeStamp.toLocaleDateString()} at {BuildTimeStamp.toLocaleTimeString()}</span>
      </div>

  );
}

export default App;
