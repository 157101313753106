export const filterToSort = {
  'experience': 'exp',
  'kills': 'kill',
  'headshots': 'headshot',
  'sniper kills': 'sniper-kill',
  'revivals': 'revival',
  'heals': 'heal',
  'knife kills': 'knife-kill',
  'time in zone': 'time-in-zone',
}

export const responseSortMap = {
  'exp': 'exp',
  'kills': 'kills',
  'headshots': 'heads',
  'sniper kills': 'sniper-kill',
  'revivals': 'revival',
  'heals': 'heal',
  'knife kills': 'knife-kill',
  'time in zone': 'time-in-zone',
}
