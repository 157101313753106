import axios from 'axios';

const http = axios.create({
  baseURL: `https://ns548971.ip-66-70-179.net:80/tfe-rest-api/v1`,
  timeout: 5000,
});

export const getTop100 = async (args) => {
  const { searchStatsType, filter } = args;
  const { data } = await http.get(`/player-stats/top100?stats-type=${searchStatsType}${filter ? `&filter=${filter}` : ''}`)

  return data.data.players;
}

export const getPlayerStats = async (args) => {
  const { searchStatsType, filter, searchType, searchTerm } = args;

  const arg = searchType === 'SteamID' ? 'steamid' : 'display-name';
  const { data } = await http.get(`/player-stats/single?${arg}=${searchTerm}&stats-type=${searchStatsType}${filter ? `&filter=${filter}` : ''}`)

  return data.data.players;
}
